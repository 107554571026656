<!-- <div class="container">
    <div class="form-group">
        <div>
            XMPP domain
            <small>(e.g. jabber.example.com)</small>
        </div>
        <div>
            <input name="domain" [(ngModel)]="domain" class="form-control" type="text" placeholder="jabber.example.com"/>
        </div>
    </div>

    <div class="form-group">
        <div>
            Host Websocket endpoint
            <small>(e.g. wss://jabber.example.com:5280/websocket)</small>
        </div>
        <div>
            <input name="service" [(ngModel)]="service" class="form-control" type="text"
                   placeholder="wss://jabber.example.com:5280/websocket"/>
        </div>
    </div>

    <div class="form-group">
        <div>
            Username
            <small>(e.g. test if test@jabber.example.com is your full JID)</small>
        </div>
        <div>
            <input name="username" [(ngModel)]="username" class="form-control" type="text" placeholder="test"/>
        </div>
    </div>

    <div class="form-group">
        <div>
            Password
        </div>
        <div>
            <input name="password" [(ngModel)]="password" class="form-control" type="password" placeholder="Password"/>
        </div>
    </div>

    <div>
        <button name="login" (click)="onLogin()">Log in</button>
        <button name="logout" (click)="onLogout()">Log out</button>
        <button name="register" (click)="onRegister()">Register</button>
        <button name="reconnect" (click)="onReconnect()">Reconnect</button>
    </div>

    <div>State: {{chatService.state$ | async}}</div>

    <div *ngIf="registrationMessage">
        <p>{{registrationMessage}}</p>
    </div>

    <hr/>

    <div class="form-group">
        <div>
            Contact Management
        </div>
        <div>
            <input [(ngModel)]="otherJid" class="form-control" type="text" placeholder="JID"/>
        </div>
    </div>

    <button (click)="onAddContact()">Add contact</button>
    <button (click)="onRemoveContact()">Remove contact</button>
    <button (click)="onOpenChat()">Open chat</button>

    <hr/>

    <button (click)="chatService.enableDebugging = !chatService.enableDebugging">Enable debugging</button>


    <div>
        <h2>Custom component for a chat session</h2>
        <ng-container *ngIf="(this.chatService.state$ | async) === 'online' && (this.chatService.contactsSubscribed$ | async).length > 0; else messagesComponentInformation">
            <ngx-chat-message-list [recipient]="(this.chatService.contactsSubscribed$ | async)[0]"
                                   [showAvatars]="true"></ngx-chat-message-list>
            <ngx-chat-message-input [recipient]="(this.chatService.contactsSubscribed$ | async)[0]"></ngx-chat-message-input>
        </ng-container>
        <ng-template #messagesComponentInformation>You need to be logged in and have contacts to see this component</ng-template>
    </div>


    <hr/>

    <app-iq></app-iq>

    <app-send-stanza></app-send-stanza>

    <app-multi-user-chat></app-multi-user-chat>

    <div>
        <h2>Unread messages count:</h2>
        Sum unread messages: {{unreadMessageCountPlugin.unreadMessageCountSum$ | async}}
        <pre>{{unreadMessageCountPlugin.jidToUnreadCount$ | async | keyvalue | json}}</pre>
    </div>
    <h2>More:</h2>
    <a routerLink="/ui">Show mock chats</a>
    <br>
    <a routerLink="/muc">Show multi user chat</a>
</div> -->

<ngx-chat rosterState="shown"></ngx-chat>
