<div *ngIf="showChatComponent; else elseBlock">
    <ngx-chat-window-list
                        [rosterState]="rosterState">
    </ngx-chat-window-list>
    <ngx-chat-roster-list [rosterState]="rosterState"
                        [contacts]="contacts"
                        [contactRequestsReceived$]="contactRequestsReceived$"
                        [contactRequestsSent$]="contactRequestsSent$"
                        [contactsUnaffiliated$]="contactsUnaffiliated$"

                        (rosterStateChanged)="onRosterStateChanged($event)">
    </ngx-chat-roster-list>
</div>
<ng-template #elseBlock >

	<p *ngIf="numberOfRetries >= 3; else elseLoader">There is some error in loading chat control. Please logout and login again to fix this error.</p>
    <ng-template #elseLoader>
        <div class="loader"></div>
    </ng-template>
</ng-template>
<!-- else
this will show  a div
button to reconnect will only be shown wehn retry coount goes beyond 3
prior to 3 a loader dive will be shown



-->
