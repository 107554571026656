<div>
    <h2>Chat rooms:</h2>
    <div *ngIf="(chatService.state$ | async) === 'online'; else offline">
        <div *ngFor="let room of (multiUserChatPlugin.rooms$ | async)">
            {{room.roomJid.bare().toString()}}:
            <button (click)="selectedRoom = room">Select</button>
            <button (click)="leaveRoom(room.occupantJid)" class="red">Leave</button>
        </div>

        <ng-container *ngIf="selectedRoom">
            <ngx-chat-message-list [recipient]="selectedRoom"></ngx-chat-message-list>
            <ngx-chat-message-input [recipient]="selectedRoom"></ngx-chat-message-input>
        </ng-container>
        <span *ngIf="(multiUserChatPlugin.rooms$ | async).length === 0">(no room joined yet)</span>

        <hr/>

        <input [(ngModel)]="occupantJidText" (ngModelChange)="updateOccupantJid($event)" class="form-control"
               #occupantJidInput="ngModel"
               [ngClass]="occupantJidInput.control.invalid ? 'red' : null"
               type="text" placeholder="room"/>

        <button (click)="joinRoom(occupantJid)">Join room</button>
        <button (click)="subscribeWithMucSub(occupantJid)">subscribe with MUC/Sub</button>
        <button (click)="unsubscribeFromMucSub(occupantJid)">unsubscribe from MUC/Sub</button>
        <button (click)="getSubscriptions()">get MUC/Sub subscriptions</button>
        <button (click)="queryUserList(occupantJid)">get user list</button>
        <button (click)="getRoomConfiguration(occupantJid)">get room configuration</button>

        <ng-container *ngIf="mucSubSubscriptions.size > 0">
            <div>Current MUC subscriptions to room: {{occupantJid}}</div>
            <ul>
                <li *ngFor="let subscription of mucSubSubscriptions | keyvalue">
                    {{subscription.key}}:
                    <ul>
                        <li *ngFor="let subscribedNode of subscription.value">
                            {{subscribedNode}}
                        </li>
                    </ul>
                </li>
            </ul>
        </ng-container>

        <br>
        <ng-container *ngIf="roomUserList.length > 0">
            <div>Members of room: {{occupantJid.bare().toString()}}</div>
            <table>
                <thead>
                <tr>
                    <th>JID</th>
                    <th>Nicks</th>
                    <th>Affiliation</th>
                    <th>Role</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let member of roomUserList">
                    <td>{{displayMemberJid(member)}}</td>
                    <td>{{displayMemberNicks(member) || '[unknown]'}}</td>
                    <td>{{member.affiliation}}</td>
                    <td>{{member.role}}</td>
                    <td>
                        <button (click)="kick(member)">
                            Kick ({{findIdWithNick(member)?.nick || '[unknown]'}})
                        </button>
                        <button (click)="banOrUnban(member)">
                            Ban/Unban
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </ng-container>

        <ng-container *ngIf="(roomConfiguration?.fields.length || 0) > 0">
            <p>Configuration of room: {{occupantJid.bare().toString()}}</p>
            <table>
                <thead>
                <tr>
                    <th>Variable</th>
                    <th>Value</th>
                    <th>Label</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let field of roomConfiguration.fields">
                    <tr *ngIf="field.type !== 'hidden'">
                        <td>{{field.variable}}</td>
                        <td>{{field.value}}</td>
                        <td>{{field.label}}</td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </ng-container>
        <hr/>

        <button (click)="queryAllRooms()">List all rooms</button>

        <div *ngFor="let room of allRooms">
            <p>{{room.name}}: {{room.jid.toString()}}</p>
            <details>
                <summary>Room info</summary>
                <table>
                    <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Value</th>
                        <th>Label</th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="let field of room.roomInfo.fields">
                        <tr *ngIf="field.type !== 'hidden'">
                            <td>{{field.variable}}</td>
                            <td>{{field.value}}</td>
                            <td>{{field.label}}</td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </details>
            <button (click)="joinRoom(room.jid)">join</button>
            <button (click)="subscribeWithMucSub(room.jid)">subscribe with MUC/Sub</button>
            <button (click)="unsubscribeFromMucSub(room.jid)">unsubscribe from MUC/Sub</button>
            <button (click)="destroyRoom(room.jid)">destroy</button>
        </div>

        <hr/>

        <button *ngIf="newRoom == null" (click)="createNewRoom()">Create new room</button>

        <form (submit)="createRoomOnServer()" *ngIf="newRoom != null">
            <div class="form-group">
                <input name="roomId"
                       [(ngModel)]="newRoom.roomId"
                       class="form-control"
                       type="text"
                       placeholder="Room id"
                       required="required"
                       #roomId="ngModel"/>
                <div [hidden]="roomId.valid || roomId.pristine"
                     class="alert alert-danger">
                    Room id is required
                </div>
            </div>
            <div class="form-group">
                <input name="roomName"
                       class="form-control"
                       [(ngModel)]="newRoom.name"
                       type="text"
                       placeholder="Room name"/>
            </div>
            <label>
                <input name="membersOnly" [(ngModel)]="newRoom.membersOnly" type="checkbox"/>
                members only
            </label>
            <br>
            <label>
                <input name="nonAnonymous" [(ngModel)]="newRoom.nonAnonymous" type="checkbox"/>
                non-anonymous
            </label>
            <br>
            <label>
                <input name="persistentRoom" [(ngModel)]="newRoom.persistentRoom" type="checkbox"/>
                persistent
            </label>
            <br>
            <label>
                <input name="public" [(ngModel)]="newRoom.public" type="checkbox"/>
                public
            </label>
            <br>
            <label>
                <input name="allowSubscription" [(ngModel)]="newRoom.allowSubscription" type="checkbox"/>
                allow subscription
            </label>
            <br>
            <button type="submit">Create room</button>
            <button (click)="cancelRoomCreation()">Cancel room creation</button>
        </form>
    </div>

    <hr/>

    <ng-template #offline>
        <span *ngIf="(chatService.state$ | async) !== 'online'">You need to be online.</span>
    </ng-template>
</div>
