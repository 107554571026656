<ngx-chat-window-frame (headerClick)="onClickHeader()" (closeClick)="onClickClose()">

    <ng-container class="window-header-content">
        <div class="chat-contact-avatar-wrapper" (click)="onContactClick($event)">
            <ngx-chat-avatar [imageUrl]="chatWindowState.recipient.avatar"></ngx-chat-avatar>
        </div>

        <div class="header-middle">

            <div class="chat-contact-name-wrapper" [title]="chatWindowState.recipient.name">
                <span [ngClass]="{'has-click-handler': !chatWindowState.isCollapsed && !!this.contactClickHandler}"
                    (click)="onContactClick($event)">
                    {{chatWindowState.recipient.name}}
                </span>
            </div>

            <div class="chat-contact-status-wrapper"
                *ngIf="!chatWindowState.isCollapsed && chatWindowState.recipient.recipientType === 'contact'">
                <span class="chat-contact-status">
                    {{chatService.translations.presence[chatWindowState.recipient.presence$ | async]}}
                </span>
            </div>

        </div>
    </ng-container>

    <ngx-chat-filedrop *ngIf="!chatWindowState.isCollapsed" class="window-content"
        [enabled]="fileUploadHandler.isUploadSupported()" (fileUpload)="uploadFile($event)"
        [dropMessage]="chatService.translations.dropMessage">
        <div (click)="onFocus()">
            <ngx-chat-message-list [recipient]="chatWindowState.recipient"
                [showAvatars]="false"></ngx-chat-message-list>
            <div class="chat-input-container">
                <ngx-chat-message-input [recipient]="chatWindowState.recipient"
                    (messageSent)="afterSendMessage()"></ngx-chat-message-input>

                <div *ngFor="let action of chatService.chatActions" (click)="onActionClick(action)"
                    class="bi bi-send mySize" [ngClass]="action.cssClass" [innerHTML]="" style="color: rgb(16, 17, 16); ">
                </div>

                <!-- <div class="chat-action chat-window-send ng-star-inserted">
                    <input class="form-control" type="file" multiple (change)="onChange($event)" hidden #file>
                    <button (click)="file.click()" style="background: transparent; border: none;">
                        <i class="bi bi-paperclip mySize solid" style="color: rgb(16, 17, 16); "></i>
                    </button>
                </div>
                <div class="chat-action chat-window-send ng-star-inserted">
                    <button style="background: transparent; border: none;">
                        <i class="bi bi-mic-fill record-mic mySize solid" (click)="recordAudio()" style="color: rgb(16, 17, 16); "></i>
                    </button>
                </div> -->

            </div>
        </div>
    </ngx-chat-filedrop>

</ngx-chat-window-frame>
