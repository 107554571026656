import { Component, Inject, OnInit } from "@angular/core";
import {
  CHAT_SERVICE_TOKEN,
  ChatBackgroundNotificationService,
  ChatListStateService,
  ChatService,
  ContactFactoryService,
  LogInRequest,
  LogLevel,
  LogService,
  MultiUserChatPlugin,
  RegistrationPlugin,
  UnreadMessageCountPlugin,
} from "@pazznetwork/ngx-chat";

import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.css"],
})
export class IndexComponent implements OnInit {
  domain?: string;
  service?: string;
  password?: string;
  username?: string;
  otherJid?: string;
  readonly multiUserChatPlugin: MultiUserChatPlugin;
  readonly unreadMessageCountPlugin: UnreadMessageCountPlugin;
  registrationMessage?: string;

  constructor(
    @Inject(CHAT_SERVICE_TOKEN) public chatService: ChatService,
    private contactFactory: ContactFactoryService,
    private logService: LogService,
    private chatListStateService: ChatListStateService,
    chatBackgroundNotificationService: ChatBackgroundNotificationService,
    private route: ActivatedRoute
  ) {
    const contactData: {
      domain?: string;
      service?: string;
      password?: string;
      username?: string;
    } = JSON.parse(localStorage.getItem("data")) || {};
    this.logService.logLevel = LogLevel.Debug;
    this.domain = contactData.domain;
    this.service = contactData.service;
    this.password = contactData.password;
    this.username = contactData.username;

    this.chatService.state$.subscribe((state) => this.stateChanged(state));
    this.multiUserChatPlugin = this.chatService.getPlugin(MultiUserChatPlugin);
    this.unreadMessageCountPlugin = this.chatService.getPlugin(
      UnreadMessageCountPlugin
    );

    chatBackgroundNotificationService.enable();

    // @ts-ignore
    window.chatService = chatService;
  }

  userId: string = "";
  ngOnInit() {
    //debugger;
    this.route.queryParams.subscribe((params) => {
      console.log(params); // { orderby: "price" }
      this.userId = params.userid;
      console.log(this.userId + " My credentilas"); // price
    });

    if (this.userId != null && this.userId != "") {

      const logInRequest: LogInRequest = {
        domain: "chat.mahamma.com",
        service: "wss://chat.mahamma.com:5443/ws",
        password: this.userId,
        username: this.userId+"@chat.mahamma.com",
      };

      localStorage.setItem("data", JSON.stringify(logInRequest));
      this.chatService.logIn(logInRequest);
    }
    else{
        console.log("Please provide user id through url");
    }
  }

  onLogin() {}

  onLogout() {
    this.chatService.logOut();
  }

  async onRegister() {
    this.registrationMessage = "registering ...";
    try {
      await this.chatService
        .getPlugin(RegistrationPlugin)
        .register(this.username, this.password, this.service, this.domain);
      this.registrationMessage = "registration successful";
    } catch (e) {
      this.registrationMessage = "registration failed: " + e.toString();
      throw e;
    }
  }

  onAddContact() {
    this.chatService.addContact(this.otherJid);
  }

  onRemoveContact() {
    this.chatService.removeContact(this.otherJid);
  }

  onOpenChat() {
    this.chatListStateService.openChat(
      this.chatService.getOrCreateContactById(this.otherJid)
    );
  }

  private async stateChanged(state: "disconnected" | "connecting" | "online") {
    console.log("state changed!", state);
  }

  onReconnect() {
    this.chatService.reconnect();
  }
}
