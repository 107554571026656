<div class="container">

    <a routerLink="/">Back to ngx-chat sample component</a>

    <div class="example">
        <p>Default theme (avatars)</p>
        <div class="chat-message-list-wrapper">
            <ngx-chat-message-list [recipient]="contact"
                                   [showAvatars]="true"></ngx-chat-message-list>
        </div>
    </div>

    <div class="example">
        <p>Default theme (no avatars)</p>
        <div class="chat-message-list-wrapper">
            <ngx-chat-message-list [recipient]="contact"
                                   [showAvatars]="false"></ngx-chat-message-list>
        </div>
    </div>

    <div class="example">
        <p>Simple chat message component used directly</p>
        <div class="chat-message-list-wrapper simple-message-list">
            <ngx-chat-message-simple class="chat-message--out"
                                     [avatar]="dummyAvatarContact"
                                     [direction]="Direction.out"
                                     formattedDate="2020-06-04 18:35"
                                     nick="chat partner"
                                     [messageState]="MessageState.SENDING">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ngx-chat-message-simple>
            <ngx-chat-message-simple class="chat-message--out"
                                     [avatar]="dummyAvatarContact"
                                     [direction]="Direction.out"
                                     formattedDate="2020-06-04 18:35"
                                     nick="chat partner"
                                     [messageState]="MessageState.SENDING">
                sending
            </ngx-chat-message-simple>
            <ngx-chat-message-simple class="chat-message--out"
                                     [avatar]="dummyAvatarContact"
                                     [direction]="Direction.out"
                                     formattedDate="2020-06-04 18:35"
                                     nick="chat partner"
                                     [messageState]="MessageState.SENT">
                sent
            </ngx-chat-message-simple>
            <ngx-chat-message-simple class="chat-message--out"
                                     [avatar]="dummyAvatarContact"
                                     [direction]="Direction.out"
                                     formattedDate="2020-06-04 18:35"
                                     nick="chat partner"
                                     [messageState]="MessageState.RECIPIENT_RECEIVED">
                recipient received
            </ngx-chat-message-simple>
            <ngx-chat-message-simple class="chat-message--out"
                                     [avatar]="dummyAvatarContact"
                                     [direction]="Direction.out"
                                     formattedDate="2020-06-04 18:36"
                                     nick="chat partner"
                                     [messageState]="MessageState.RECIPIENT_SEEN">
                recipient seen
            </ngx-chat-message-simple>
            <ngx-chat-message-simple class="chat-message--out"
                                     [direction]="Direction.out"
                                     formattedDate="2020-06-04 18:37"
                                     imageLink="https://dummyimage.com/600x400/000/fff"
                                     nick="chat partner">
                content goes here
            </ngx-chat-message-simple>
        </div>
    </div>

    <div class="example">
        <p>Mock for multi user chat room</p>
        <div class="chat-message-list-wrapper">
            <ngx-chat-message-list [recipient]="room"></ngx-chat-message-list>
        </div>
    </div>
</div>
